import React from "react"
import { graphql } from "gatsby"

import theme from "../themes/theme"

import { PageLayout } from "../components/PageLayout"

import Img from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  // HeroGrid,
  // HeroSection,
  HeroHeadline,
  HeroSubHead,
  // HeroExtra,
  // HeroButton,
} from "../components/HeroSection"

import { StyledIcon } from "../components/StyledIcon"

import {
  SocialFacebook,
  SocialLinkedIn,
  SocialInstagram,
  SocialTwitter,
} from "../components/SocialIcons"

import {
  RowSection,
  TwoColumnSection,
  TwoColumnSectionLeft,
  TwoColumnSectionRight,
} from "../components/SectionBlocks"

import SEO from "../components/seo"

const PageContactThankYou = ({ data, location }) => {
  return (
    <PageLayout>
      <SEO
        title="Contact Us"
        ogImage={data?.socialImage?.childImageSharp?.fixed.src}
        description="Coaching professionals to look great, get stronger & play harder"
        url={location.origin + location.pathname}
      />

      <div className="relative w-full z-10">
        <div className="absolute inset-0 -z-20 w-full">
          <Img
            fluid={data?.heroImageMitchAlex?.childImageSharp?.fluid}
            alt="Club Bunker"
            objectFit="cover"
            className="md:-z-10 hidden md:block h-full"
          />

          {/* <GatsbyImage
            image={getImage(data?.heroImageMitchAlex2)}
            className="md:-z-10 hidden md:block md:opacity-30 h-full"
          /> */}
        </div>
        <div className="z-20">
          <div className="md:max-w-[50%]">
            <div className="m-2 md:m-8 bg-primaryBlueNormal rounded-xl">
              <div className="p-4 md:p-8 text-center">
                <p className="font-bold text-4xl text-center text-LPwhite text-center md:text-left font-serif">
                  <DisplayWelcomeLine />
                </p>
                <p className="font-normal text-base text-center text-LPwhite text-center md:text-left font-serif">
                  While you're waiting, come and join our communnity and get to know us better.
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <StyledIcon padding="3px" size="50px">
                    <SocialLinkedIn
                      paddingLeft="10px"
                      hoverColor={theme.colors.primaryOrangeNormal}
                    />
                    <SocialFacebook
                      paddingLeft="10px"
                      hoverColor={theme.colors.primaryOrangeNormal}
                    />
                    <SocialInstagram
                      paddingLeft="10px"
                      hoverColor={theme.colors.primaryOrangeNormal}
                    />
                    <SocialTwitter
                      paddingLeft="10px"
                      hoverColor={theme.colors.primaryOrangeNormal}
                    />
                  </StyledIcon>
                </div>
                {/* <MoreInfoForm /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default PageContactThankYou

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }

    heroImage: file(absolutePath: { regex: "/mitch-kettlebell-row-1920x874.jpg/" }) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImageMitchAlex: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-1920x874.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImageMitchAlex2: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-1920x874.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          aspectRatio: 1.77
          formats: [AVIF, AUTO, WEBP]
          placeholder: BLURRED
        )
      }
    }

    socialImage: file(absolutePath: { regex: "/LiftologyProject-Banner-Squat-1200x628.jpg/" }) {
      childImageSharp {
        fixed(width: 1200, height: 628, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
const DisplayWelcomeLine = () => {
  if (typeof window !== "undefined") {
    let firstName = localStorage.getItem("FirstName")
    return "Thanks " + firstName + ", one of our Coaches will be in touch shortly"
  } else {
    return "One of our Coaches will be in touch shortly"
  }
}
